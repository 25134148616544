.menu-button {
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
}

.menu-button-line {
  width: 40px;
  height: 3px;
  background-color: #fff;
  border-radius: 25px;
}

@media (min-width: 769px) {
  .menu-button {
    display: none;
  }
}
