.activeLink div {
  background-color: #303030;
}

.nav-drawer {
  background-color: #222;
  height: 100%;
  width: 70%;
  max-width: 400px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.2s ease-out;
  /* -webkit-transition: transform 0.3s ease-out;
  -moz-transition: transform 0.3s ease-out;
  -ms-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out; */
}

.nav-drawer.open {
  transform: translateX(0px);
  transition: transform 0.3s ease-out;
}

.nav-drawer-item {
  text-decoration: none;
  flex-grow: 1;
}

.nav-drawer-item-div {
  height: 100%;
  width: 100%;
  flex-grow: 1;
  color: white;
  font-size: 0.875em;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 200;
}
