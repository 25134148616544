.App {
  height: 100%;
  display: flex;
}

.main {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .main {
    margin-left: 0px;
  }
}

@media (min-width: 769px) {
  .main {
    margin-left: 100px;
  }
}

.page-title-container {
  display: flex;
}

.page-title {
  flex-grow: 1;
}

.bottom-div {
  height: 70px;
}
