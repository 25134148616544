.active-nav-link div {
  background-color: #303030;
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nav-item {
  text-decoration: none;
  max-height: 100px;
  flex-basis: 100px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-item-div {
  width: 100px;
  height: 100%;
  padding: 10px;
  color: white;
  outline: 1px solid black;
  font-size: 0.875em;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }
}
